import { Button, ButtonSubmit, Modal } from '@flipgrid/flipkit';
import { useNavigation, Form } from '@remix-run/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import routes from '~/constants/routes';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { Group } from 'types/Group';

type Props = {
  groupInfo: Group;
  onRequestClose: OnRequestCloseType;
  onLeaveGroup: () => void;
};

const LeaveGroupModal = ({ onRequestClose, groupInfo, onLeaveGroup }: Props) => {
  const { t } = useTranslation();
  const navigation = useNavigation();

  const isActionRedirect =
    navigation.state === 'loading' &&
    navigation.formMethod !== null &&
    navigation.formMethod !== 'GET' &&
    // We had a submission navigation and are now navigating to different location
    navigation.formAction !== navigation.location.pathname;

  useEffect(() => {
    if (isActionRedirect) {
      onLeaveGroup();
      onRequestClose(t('leaveGroupModal.successfullyLeftGroup', { groupName: groupInfo.name }));
    }
  }, [groupInfo.name, isActionRedirect, onLeaveGroup, onRequestClose, t]);

  return (
    <Modal onClose={onRequestClose}>
      <Form method="post" action={routes.GROUPS_ID_FUNC(groupInfo.id)}>
        <h1 className="fk-modalHeader">{t('leaveGroupModal.leaveThisGroup')}</h1>
        <div className="fk-modalBody">
          <p>{t('leaveGroupModal.noLongerAccess', { groupName: groupInfo.name })}</p>
        </div>
        <Modal.Actions className="mt2">
          <Button theme="secondary" onClick={onRequestClose} data-testid="leaveGroupModal__button__cancel">
            {t('common.cancel')}
          </Button>
          <ButtonSubmit
            name="_action"
            value="leaveGroup"
            theme="danger"
            loading={navigation.state !== 'idle'}
            data-testid="leaveGroupModal__button__leave"
          >
            {t('shared.leaveGroup')}
          </ButtonSubmit>
          <input type="hidden" name="_action" value="leaveGroup" />
          <input type="hidden" name="groupId" value={groupInfo.id} />
          <input type="hidden" name="memberId" value={groupInfo.membership?.id} />
          <input type="hidden" name="userId" value={groupInfo.user_grid.user_id} />
        </Modal.Actions>
      </Form>
    </Modal>
  );
};

export default LeaveGroupModal;
