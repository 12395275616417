export function downloadCsv(content, fileName, passedMimeType, isSafari) {
  const a = document.createElement('a');
  const mimeType = passedMimeType || 'application/octet-stream';

  if (navigator.msSaveBlob) {
    // IE10
    return navigator.msSaveBlob(new Blob([content], { type: mimeType }), fileName);
  }

  if ('download' in a) {
    // html5 A[download]
    a.href = `data:${mimeType},${encodeURIComponent(content)}`;
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return true;
  }

  if (isSafari) {
    const blob = new window.Blob([content], { type: 'text/csv, charset=UTF-8' });
    window.location.href = window.URL.createObjectURL(blob);
  }
  // do iframe dataURL download (old ch+FF):
  const f = document.createElement('iframe');
  document.body.appendChild(f);
  f.src = `data:${mimeType},${encodeURIComponent(content)}`;

  setTimeout(() => {
    document.body.removeChild(f);
  }, 333);
  return true;
}
