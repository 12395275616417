import { isEmpty } from 'lodash-es';

import type { DisplayGroup, Group } from 'types';
import type { LoggedOutGroup } from 'types/DisplayUser';

export default function checkIsLtiGroup(group: Group | DisplayGroup | LoggedOutGroup) {
  if (!group || isEmpty((group as Group)?.settings)) return false;

  const groupAsGroup = group as Group;
  const isLtiGroup = !!(groupAsGroup.settings && groupAsGroup.settings.type === 'lti');
  return isLtiGroup;
}
